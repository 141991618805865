import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Grid, Button, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { GET_ROUND, GET_EVENTS, EDIT_ROUND } from '../../../gql/queries'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import './AveragesStyle.css'
import TextInput from '../../core/Text'
import { useSnackbar } from 'notistack'

const AveragesModal = () => {
  const { key: roundId } = useParams()
  const { request } = useRequest()
  const { handleSubmit, register } = useForm()

  const [eventData, setEventData] = useState([])
  const [statistics, setStatistics] = useState(null)

  const textClasses = useTextStyles()

  // Fetch round and events data
  const { data: fetchedRound } = useQuery(
    ['round', roundId],
    () => request({ query: GET_ROUND, variables: { _id: roundId }, endpoint: endpoints[process.env.REACT_APP_ENDPOINT] }),
    { enabled: !!roundId },
  )

  const { data: fetchedEvents } = useQuery(
    ['events', roundId],
    () => request({ query: GET_EVENTS, variables: { round: roundId }, endpoint: endpoints[process.env.REACT_APP_ENDPOINT] }),
    { enabled: !!roundId },
  )

  useEffect(() => {
    if (fetchedEvents?.getEvents?.items) {
      // Check if statistics exist, otherwise use default empty structure
      const adminAverages = fetchedRound?.getRound?.statistics?.roundAverages?.adminAverages || { events: [] }

      // Iterate over events fetched from the round's statistics
      const newEventData = fetchedEvents.getEvents.items.map((event) => {
        // Find matching event statistics from the fetchedRound
        const eventStatistics = adminAverages.events.find((ev) => ev.eventId === event._id) || {}

        return {
          ...event,
          // Ensure tickets is an array, default to an empty array if it's null/undefined
          tickets: Array.isArray(event.tickets)
            ? event.tickets.map((ticket) => {
              // Find matching subject statistics for the event
              const subject = eventStatistics?.subjects?.find((sub) => sub.subjectId === ticket._id)

              return {
                ...ticket,
                // Render the averageScore if available, otherwise set to 0
                average: subject?.averageScore || 0,
              }
            })
            : [], // Default to an empty array if tickets is not available
        }
      })

      // Update state to render the new event data with average scores
      setEventData(newEventData)

      // Set statistics if they exist, otherwise use a default structure
      setStatistics(fetchedRound?.getRound?.statistics || {
        roundAverages: {
          adminAverages: {
            events: [],
          },
        },
      })
    }
  }, [fetchedRound, fetchedEvents])

  // Handle input change to update the statistics object
  const handleAverageChange = (e, eventIndex, ticketIndex) => {
    const updatedEventData = [...eventData]
    const newScore = parseFloat(e.target.value) || 0

    // Update average in eventData
    updatedEventData[eventIndex].tickets[ticketIndex].average = newScore
    setEventData(updatedEventData)

    // Update statistics object
    const updatedStatistics = { ...statistics }
    const eventId = updatedEventData[eventIndex]._id
    const ticketId = updatedEventData[eventIndex].tickets[ticketIndex]._id

    let eventObj = updatedStatistics.roundAverages.adminAverages.events.find((event) => event.eventId === eventId)
    if (!eventObj) {
      eventObj = { eventId, name: updatedEventData[eventIndex].name.en, subjects: [] }
      updatedStatistics.roundAverages.adminAverages.events.push(eventObj)
    }

    let subjectObj = eventObj.subjects.find((subject) => subject.subjectId === ticketId)
    if (!subjectObj) {
      subjectObj = { subjectId: ticketId, name: updatedEventData[eventIndex].tickets[ticketIndex].name, averageScore: newScore }
      eventObj.subjects.push(subjectObj)
    }

    subjectObj.averageScore = newScore
    setStatistics(updatedStatistics)
  }
  const { enqueueSnackbar } = useSnackbar()
  // Submit the updated statistics
  const updateStatistics = () => {
    const variables = {
      _id: roundId,
      name: { en: fetchedRound?.getRound?.name?.en },
      startDate: fetchedRound?.getRound?.startDate,
      endDate: fetchedRound?.getRound?.endDate,
      statistics,
      showStatistics: fetchedRound?.getRound?.showStatistics || false,
    }

    request({ query: EDIT_ROUND, variables, endpoint: endpoints[process.env.REACT_APP_ENDPOINT] }).then(() => {
      enqueueSnackbar('Averages Added Successfully!', { variant: 'success' })
    }).catch(() => {
      enqueueSnackbar('An Unexpected error occurred!', { variant: 'error' })
    })
  }

  return (
    <>
      <h1 className={textClasses.mainHeader}>Round: {fetchedRound?.getRound?.name?.en}</h1>
      <Grid container direction='column' alignItems='center' style={{ minHeight: '100vh' }}>
        <form style={{ width: '80%' }} onSubmit={handleSubmit(updateStatistics)}>
          {eventData && eventData.map((event, eventIndex) => (
            <div key={event._id}>
              <h1 className='event-name'>{event.name.en}</h1>
              <Grid container spacing={2} alignItems='center'>
                {event.tickets?.length > 0 && event.tickets.map((ticket, ticketIndex) => (
                  <Grid item lg={6} sm={12} key={ticketIndex}>
                    <h3>{ticket.name}</h3>
                    <TextInput
                      inputType='number'
                      placeholder='average'
                      value={ticket.average || 0}
                      onChange={(e) => handleAverageChange(e, eventIndex, ticketIndex)}
                      {...register(`events.${eventIndex}.tickets.${ticketIndex}.average`)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
          <Grid item spacing={2} lg={4}>
            <Box display='flex' justifyContent='start' style={{ marginTop: 40 }}>
              <Button color='primary' variant='contained' disableElevation size='large' type='submit'>
                Submit
              </Button>
            </Box>
          </Grid>
        </form>

      </Grid>
    </>
  )
}

const useTextStyles = makeStyles((theme) => ({
  mainHeader: {
    color: theme.palette.info.main,
    fontSize: '2.5rem',
    fontWeight: 400,
    marginTop: '3rem',
    marginBottom: '2.5rem',
    textAlign: 'center',
  },
}))

export default AveragesModal
